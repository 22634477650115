.terminal {
  height: 100%;
  overflow: hidden;
  background-color: #3c3c3c;
  color: #c4c4c4;
  padding: 45px 35px;
  font-size: 14px;
  line-height: 1.42;
  font-family: "IBM Plex Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.terminalHeader {
  width: 100%;
  height: 34px;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 10005;
}

.terminalHeaderTitle {
  margin-left: 10px;
  color: #fff;
}

.terminalLine {
  line-height: 2;
  white-space: pre-wrap;
}

.terminalPrompt {
  display: flex;
  align-items: center;
  margin-bottom: 300px;
}

.terminalPromptLabel {
  flex: 0 0 auto;
  color: #f9ef00;
}

.terminalOromptDirectory {
  flex: 0 0 auto;
  color: #cacaca;
}

.terminalPromptRoot {
  flex: 0 0 auto;
  color: #cacaca;
  margin-left: 6px;
}

.terminalPromptInput {
  flex: 1;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: white;
}

.terminalPromptInput input {
  flex: 1;
  width: 100%;
  background-color: transparent;
  color: white;
  border: 0;
  outline: none;
  font-size: 14px;
  line-height: 1.42;
  font-family: "IBM Plex Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
}

.terminalLineAnimation {
  line-height: 1.1;
  font-size: 14px;
  white-space: pre-wrap;
  color: #cacaca;
  font-family: "IBM Plex Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
}

.terminalRow {
  display: flex;
  clear: both;
  margin: 0;
  padding: 0;
  height: 30px;
}

.terminalCommand {
  display: flex;
  clear: both;
  margin: 0;
  padding: 0;
  width: 270px;
  font-weight: bold;
  color: #fff;
}

.terminalCommandText {
  display: flex;
  margin: 0;
  padding: 0;
  clear: both;
}

.filesystemRow {
  display: flex;
  margin: 0;
  padding: 0;
  clear: both;
}

.filesystemRowDirectory {
  width: 200px;
  margin: 0;
  padding: 0;
}

.filesystemRowFile {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.terminalLink {
  color: aquamarine;
}

.terminalClose {
  color: red;
}

.terminalTextRow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.scanlines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: repeating-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03) 0%,
    rgba(255, 255, 255, 0.2) 5px,
    transparent 1px,
    transparent 3px
  );
  pointer-events: none;
  animation: scan 0.5s infinite steps(1);
}

@keyframes scan {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.refresh-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 2003;
  background: rgba(0, 255, 0, 0.2);
  pointer-events: none;
  animation: refresh 1s infinite;
}

@keyframes refresh {
  0% {
    top: -2px;
  }
  100% {
    top: 100%;
  }
}
